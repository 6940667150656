"use client";

import type { BlockAccordion } from "@/cms/schema";
import AddIcon from "@mui/icons-material/Add";
import * as Accordion from "@radix-ui/react-accordion";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { BlockProps } from "./utils";
import { blue } from "../_utils/tailwind";

export default function AccordionBlock({ block }: BlockProps<BlockAccordion>) {
  const [value, setValue] = useState<string | undefined>();

  const variants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: "auto" },
  };

  const items = block.items.map((item, i) => (
    <motion.div
      key={item.id}
      animate={i.toString() === value ? "visible" : "hidden"}
      variants={{ visible: { backgroundColor: blue.DEFAULT } }}
    >
      <Accordion.Item
        value={i.toString()}
        className="border-b-2 border-gray-600"
      >
        <Accordion.Trigger className="flex w-full items-center justify-between gap-4 p-4 text-left font-semibold text-white">
          {item.question}
          <motion.div
            className="text-gray-300"
            variants={{
              visible: { rotate: 45, transition: { ease: "easeIn" } },
              hidden: { rotate: 0, transition: { ease: "easeIn" } },
            }}
          >
            <AddIcon />
          </motion.div>
        </Accordion.Trigger>
        <Accordion.Content forceMount className="overflow-hidden">
          <AnimatePresence>
            {i.toString() === value && (
              <motion.div
                key={item.id}
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
              >
                <div
                  className="px-4 pb-4"
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </Accordion.Content>
      </Accordion.Item>
    </motion.div>
  ));

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <h2 className="w-full text-center">{block.headline}</h2>
      <Accordion.Root
        type="single"
        collapsible
        value={value}
        onValueChange={setValue}
        className="flex w-full flex-col"
      >
        {items}
      </Accordion.Root>
    </>
  );
}
